@use "./assets/scss/reset.scss";
@use 'bootstrap/scss/bootstrap.scss';
@use 'ngx-toastr/toastr';
@use 'quill/dist/quill.snow.css';
@use "./assets/scss/variables.scss";
@use "./assets/scss/helpers.scss";
@use "./assets/scss/animations.scss";
@use "./assets/scss/tooltips.scss";
@use "./assets/scss/ie.scss";
@use "./assets/scss/icons.scss";
@use "./assets/scss/im-webfont.scss";
@use "./assets/scss/fonts.scss";
@use "./assets/scss/richtext.scss";
@use "./assets/scss/tables.scss";

// general 
ol, ul{
    padding-left: 0;
}
::placeholder {
    color: var(--shade-5);
    color: #ACB5C1;
    opacity: 1;
}
[contenteditable] {
    outline: 0px solid transparent;
}
// [contenteditable]:empty::before{
//     content:attr(data-placeholder);
//     color: #ACB5C1;
// }
.theme--dark {
    ::placeholder {
        color: var(--shade-7);
    }
}
body {
    font-size: variables.$fontsize-m;
    line-height: 1;
    background: var(--shade-0);
}
.background{
    background: var(--shade-0);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}
body * {
    // font-family: proxima-nova, sans-serif;
    font-family: 'Poppins', sans-serif;
}
a{
    word-break: break-word;
}
h1, h2, h3, h4{
    margin: 0;
    color: var(--text-color);
}
h1, h1>span{
    // font-family: "uni_neueregular", sans-serif;
    // font-size: variables.$fontsize-xl;
    font-size: 32px;
    font-weight: 500;
}
h2{
    // font-family: "uni_neuebold", sans-serif;
    font-size: 26px;
}
h3{
    // font-family: "uni_neueregular", sans-serif;
    font-size: 20px;
}
h4{
    // font-family: "uni_neueregular", sans-serif;
    font-size: variables.$fontsize-l;
}
.ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
// .uni_neuelight{
//     font-family: "uni_neuelight", sans-serif;
// }
// .uni_neueregular{
//     font-family: "uni_neueregular", sans-serif;
// }
// .uni_neuebold{
//     font-family: "uni_neuebold", sans-serif;
// }
ul li {color: var(--text-color);}
ol li {color: var(--text-color);}
.scroll-y{
    overflow-y: auto;
}
.scroll-x{
    overflow-x: auto;
}
.subtitle-m{
    color: var(--shade-5);
    font-size: variables.$fontsize-l;
}
.sep{
    width: 56px;
    height: 2px;
    display: block;
    background-color: var(--primary-color);
}
main{
    min-height: 100vh;
    color: var(--text-color);
    background: var(--shade-0);
    position: relative;
    padding-top: variables.$headerHeight;
}
.container {
    max-width: 1680px !important;
    max-width: 1920px !important;
    max-width: 3840px !important;
    padding: 0;
    position: relative;
}

.descr{
    color: var(--gray);
    font-weight: 300;
}

// helpers
.saving{
    transition: .2s ease;
    opacity: .7;
    pointer-events: none;
}
.saving--pre{
    transition: .2s ease;
}
.opacity-hide{
    transition: .2s ease;
    opacity: 0;
    pointer-events: none;
}
.opacity-show{
    opacity: 1 !important;
    pointer-events: all !important;
}
.lh-1{
    line-height: 1;
}
.h-100{
    height: 100%;
}
.uppercase{
    text-transform: uppercase;
}

// toasts
#toast-container{
    .ngx-toastr{
        // box-shadow: 1px 2px 7px 0px rgba(0, 0, 0, 0.15) !important;
        // box-shadow: 0 6px 16px 0 rgba(144, 144, 144, 0.3) !important;
        box-shadow: 0 3px 12px 0 rgba(144, 144, 144, 0.2) !important;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 53px;
        padding-right: 30px;
        border-radius: 4px;
        background: var(--popup-background);
        margin: 0 0 11px;
        width: 400px;
        .toast-close-button{
            background-image: url(/assets/img/icons/x.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 10px;
            height: 10px;
            transition: .1s ease;
            position: absolute;
            top: 50%;
            right: 13px;
            transform: translatey(-50%);
            &:hover{
                opacity: .7;
            }
            span{
                display: none;
            }
        }
    }
    .toast-success{
        border-left: 5px solid var(--green);
    }
    .toast-warning{
        border-left: 5px solid var(--orange);
    }
    .toast-error{
        border-left: 5px solid var(--red);
    }
    .toast-info{
        border-left: 5px solid var(--secondary);
    }
    .toast-bottom-right {
        right: 16px;
        bottom: 0px;
    }
    .toast-title{
        font-size: variables.$fontsize-m;
        color: var(--text-color);
        // font-family: "uni_neueregular", sans-serif;
    }
    .toast-message{
        font-size: variables.$fontsize-xs;
        color: var(--shade-8);
        margin-top: 5px;
    }
    .toast-warning{
        background-image: url(/assets/img/icons/icon-toast-warning.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-error{
        background-image: url(/assets/img/icons/icon-toast-error.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-info{
        background-image: url(/assets/img/icons/icon-toast-info.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: center left 15px;
    }
    .toast-success{
        background-image: url(/assets/img/icons/icon-toast-success.svg);
        background-repeat: no-repeat;
        background-size: 21px 21px;
        background-position: center left 15px;
    }
    
}
// display
.display-label{
    font-size: variables.$fontsize-s;
    line-height: 20px;
    margin-bottom: 6px;
    color: #909090;
    // label color
    color: var(--secondary);
    font-weight: 400;
    &.uppercase{
        font-size: variables.$fontsize-xxs;
        font-weight: 500;
    }
    .theme--dark & {
        color: var(--shade-3);
    }
}
.display-row{
    // margin-bottom: variables.$spacing-m;
    margin-bottom: 40px;
    .display-value{
        margin-bottom: variables.$spacing-xs;
        color: var(--text-color);
        font-size: variables.$fontsize-display;
        line-height: 24px;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
    &:last-of-type{
        margin-bottom: 0;
    }
    .display-content{
        margin-bottom: variables.$spacing-m;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}

// lists
ul.app-list {
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
	&.app-list--table{
        li:first-of-type{
            padding-top: 0 !important;
        }
        li:last-of-type{
            border-bottom: none !important;
            padding-bottom: 0 !important;
        }
    }
	&.app-list--single{
        li:last-of-type{
            border-bottom: none !important;
            padding-bottom: 0 !important;
        }
    }
	&.app-list--field{
        margin-bottom: 0;
        margin-top: -5px;
        li:last-of-type{
            border-bottom: none !important;
            // padding-bottom: 0 !important;
        }
    }
	li {
        border-bottom: 1px solid #E6E6E6;
        padding-top: 8px;
        padding-bottom: 8px;
        color: var(--text-color);
        line-height: 24px;
        font-size: variables.$fontsize-display;
        position: relative;
        // padding-right: 12px;
        .delete-icon{
            position: absolute;
            right: 0;
            top: 15px;
        }
        .subProduct{
            display: block !important;
            margin: 0 !important;
            border: 0 !important;
            padding: 0 !important;
            margin-top: 1px !important;
            font-size: 12px !important;
            line-height: 18px !important;
            color: #787878 !important;
        }
        &::before {
            display: none;
        }
        .comment{
            margin: 0;
            border: 0;
            padding: 0;
            margin-top: 1px;
            font-size: 12px;
            line-height: 18px;
            color: #787878;
        }
	}
}
.display-value{
    ul.app-list{
        margin-top: -8px;
        margin-bottom: -8px;
    }
}

// buttons
.button{
    white-space: nowrap;
    transition: 0.2s ease;
    color: var(--white-fixed);
    box-shadow: none !important;
    outline: none !important;
    // padding: 10px 15px;
    // padding: 15px 16px 14px 18px;
    padding: 12px 16px 12px 18px;
    background: var(--primary-color);
    // border-radius: 4px;
    border: none;
    height: 0%;
    display: flex;
    align-items: center;
    font-size: variables.$fontsize-s;
    font-weight: 500;
    // letter-spacing: 1px;
    // text-transform: uppercase;
    border-radius: 4px;
    &:hover{
        background: var(--primary-color);
        filter: brightness(1.1);
    }
    &.default{
        background: var(--primary-color);
        // background: var(--primary-color);
        &:hover{
            background: var(--primary-color);
            // background: var(--primary-color);
            filter: brightness(1.1);
        }
        // ivm
        .theme--dark & {
            background-color: #BF3E9C;
        }
    }
    &.default-sm{
        background: var(--primary-color);
        line-height: 20px;
        font-size: variables.$fontsize-s;
        text-transform: none;
        padding: 8px 18px;
        &:hover{
            background: var(--primary-color);
            // background: var(--primary-color);
            filter: brightness(1.1);
        }
        // ivm
        .theme--dark & {
            background-color: #BF3E9C;
        }
    }
    &.default-xsm{
        background: var(--primary-color);
        line-height: 24px;
        font-size: variables.$fontsize-s;
        text-transform: none;
        padding: 6px 12px;
        &:hover{
            background: var(--primary-color);
            // background: var(--primary-color);
            filter: brightness(1.1);
        }
        // ivm
        .theme--dark & {
            background-color: #BF3E9C;
        }
    }
    &.secondary{
        background: var(--secondary);
        // background: var(--primary-color);
        &:hover{
            background: var(--secondary);
            // background: var(--primary-color);
            filter: brightness(1.1);
        }
    }
    &.dark{
        background: #394756;
        // background: var(--primary-color);
        &:hover{
            background: #394756;
            // background: var(--primary-color);
            filter: brightness(1.2);
        }
    }
    &.tertiary{
        background: var(--tertiary-background);
        color: var(--primary-color);
        line-height: 24px;
        font-size: variables.$fontsize-s;
        text-transform: none;
        padding: 8px 18px;
        *{color: var(--primary-color);}
        .im-icon{
            transform: translateY(-1px);
        }
        &:hover{
            // background: var(--secondary);
            // background: var(--primary-color);
            filter: brightness(1);
        }
    }
    &.default--light{
        background: var(--white);
        color: var(--secondary);
        border: 2px solid var(--secondary);
        padding: 8px 13px;
        &:hover{
            background: var(--secondary);
            color: var(--white);
            filter: none;
        }
    }
    &.delete{
        background: var(--red);
        // background: var(--primary-color);
        &:hover{
            background: var(--red);
            // background: var(--primary-color);
            filter: brightness(1.1);
        }
    }
    &:disabled,
    &.disabled{
        pointer-events: none;
        opacity: .7;
    }
    .im-icon{
        font-size: variables.$fontsize-xxs;
        margin-right: 7px;
        transform: translateY(-.5px);
        &.im-icon-plus-round{
            font-size: 16px;
            margin-right: 10px;
            transform: scale(1.2) translateY(-.5px);
        }
    }
    &.cancel{
        padding: 8px 11px;
        background: var(--popup-background);
        color: var(--shade-7);
        font-size: variables.$fontsize-s;
        border: 1px solid var(--shade-7);
    }
    &.confirm{
        padding: 8px 11px;
        background: var(--red-light);
        color: var(--white-fixed);
        font-size: variables.$fontsize-s;
        border: 1px solid var(--red-light);
    }
}
.format-link{
    color: #1098F7;
    text-decoration: underline;
    &:hover{
        color: #1098F7;
        text-decoration: underline;
    }
}
.button-link{
    white-space: nowrap;
    color: var(--secondary);
    color: #1098F7;
    font-size: variables.$fontsize-s;
    height: 0%;
    display: flex;
    align-items: center;
    &:hover span{
        text-decoration: underline;
    }
    &:disabled,
    &.disabled{
        pointer-events: none;
        opacity: .7;
    }
    &.cancel{
        padding: 8px 11px;
    }
}
.button-link.delete{
    color: var(--red-light) !important;
    font-size: variables.$fontsize-s;
}
button .im-icon{
    font-size: variables.$fontsize-xxs;
    margin-right: 5px;
    text-decoration: none !important;
}
.export-btn .im-icon{
    font-size: variables.$fontsize-xxs;
    margin-right: 5px;
}
.export-btn {
    text-decoration: none !important;
}
.export-btn:hover span {
    text-decoration: underline !important;
}
.button-row-sep{
    background: var(--shade-3);
    width: 1px;
    align-self: stretch;
}
.hightlight-hover{
    transition: .2s ease;
    &:hover{
        filter: brightness(1.1);
    }
}
.delete-icon {
    font-size: 12px;
    cursor: pointer;
    color: var(--shade-5);
}

.ql-editor {
    overflow: hidden;
}

li[data-list="ordered"] + li[data-list="bullet"]{
    margin-top: 18px;
}

// modals
.modal{
    &.main-modal .modal-dialog{
        max-width: 600px;
    }
    &.detail-modal .modal-dialog{
        width: calc(100% - #{variables.$spacing-xxl});
        height: calc(100% - #{variables.$spacing-xxl});
        max-width: calc(1920px - #{variables.$spacing-xxl});
        margin-top: variables.$spacing-m;
        margin-bottom: variables.$spacing-m;
        .modal-content{
            height: 100%;
            .theme--dark & {
                background-color: var(--background-color);
            }
        }
    }
    &.detail-modal--create .modal-dialog{
        max-width: calc(1920px - #{variables.$spacing-xxl + variables.$sidePanelWidth});
    }
    &.sub-modal .modal-dialog{
        width: calc(100% - #{variables.$spacing-xxl});
        height: calc(100% - #{variables.$spacing-xxl});
        max-width: calc(1500px - #{variables.$spacing-xxl});
        margin-top: variables.$spacing-m;
        margin-bottom: variables.$spacing-m;
        .modal-content{
            height: 100%;
        }
    }
    &.options-modal .modal-dialog{
        max-width: 800px;
        .modal-content{
            min-height: 420px;
        }
    }
    .modal-dialog{
        height: auto !important;
        // height: calc(100% - #{variables.$spacing-xxl}) !important;
        overflow: auto !important;
        border-radius: 6px !important;
        margin-top: 60px;
        box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 13%);
        box-shadow: 0px 0px 16px 0px rgb(0 0 0 / 20%);
        .modal-content{
            border-radius: 0 !important;
            // padding: 25px 30px;
            border: none;
            // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
            overflow: hidden;
            min-height: 600px;
        }
        .modal-header {
            border: none;
            align-items: center;
        }
        .modal-body {
            border: none;
        }
        .modal-footer {
            border: none;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}

.stamp{
    border: 1px solid var(--border-gray);
    display: inline-block;
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 16px;
    &.stamp--orange{
        border-color: var(--orange);
        color: var(--orange);
    }
    &.stamp--blue{
        border-color: var(--blue);
        color: var(--blue);
    }
    &.stamp--small{
        // font-size: 14px;
        // padding: 1px 2px 0px 1px;

        line-height: 16px;
        font-size: 12px;
        transform: translateY(-1px);
        padding: 0 2px 0 2px;
        margin-left: 1px;
    }
    &.stamp--primary{
        border-radius: 2px;
        border: none;
        padding: 3px 10px 2px 10px;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 13px;
        background-color: var(--primary-color);
        color: var(--white); 
    }
    &.stamp--gray{
        border-radius: 2px;
        border: none;
        padding: 3px 10px 2px 10px;
        font-size: 14px;
        background-color: var(--shade-2);
        color: var(--shade-6); 
    }
}

header nav .app-popover{
    left: 0 !important;
    margin-left: 64px !important;
}

// forms
.row-form{
    margin-bottom: 24px;
    &:last-of-type{
        margin-bottom: 0;
    }
}
.form-sep{
    height: 2px;
    width: 100%;
    background-color: #E2E3E6;
    margin-top: 48px;
    margin-bottom: 48px;
}

// fields
.freetext-comment{
    font-size: 12px;
    line-height: 16px;
    color: #787878;
    margin-top: 1px;
    white-space: pre-wrap;
}
.field-freetext{
    display: block;
    width: 100%;
    padding: 4px 8px;
    border: 1px solid var(--gray);
    border-radius: 5px;
    outline: none;
    background-color: var(--white);
    color: var(--black);
    min-height: 40px;
    line-height: 20px;
    margin-top: 12px;
    // margin-bottom: 6px;
    max-width: 400px;

    max-width: unset;
    border: 1px solid #ACB5C1;
    border-radius: 4px;
    display: block;
    width: 100%;
    font-size: 14px;
    padding: 8px 8px;
}
.label-title{
    // label color
    color: var(--secondary);
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: variables.$fontsize-s;
    line-height: 22px;
}
.standalone-label{
    color: var(--secondary);
    display: block;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: variables.$fontsize-s;
    line-height: 20px;
}
.field {
    font-size: 14px;
    .label{
        // label color
        color: var(--secondary);
        display: block;
        margin-bottom: 5px;
        font-weight: 400;
        font-size: variables.$fontsize-s;
        line-height: 20px;
        span{
            font-weight: 300;
            color: var(--shade-6);
            margin-left: 3px;
        }
    }
    .after-string{
        margin-left: 12px;
        font-size: variables.$fontsize-s;
        white-space: nowrap;
    }
    .error-field{
        border: 1px solid var(--red) !important;
        .ql-toolbar{
            border-top: 1px solid var(--red) !important;
            border-left: 1px solid var(--red) !important;
            border-right: 1px solid var(--red) !important;
        }
        .ql-container{
            border-bottom: 1px solid var(--red) !important;
            border-left: 1px solid var(--red) !important;
            border-right: 1px solid var(--red) !important;
        }
    }
    .invalid-field{
        background-color: var(--red-background);
    }
    .error-field-display {
        display: block;
        font-size: variables.$fontsize-s;
        color: var(--red);
        line-height: normal;
        padding-top: 2px;
    }
    .error-item{
        display: block;
        color: var(--red);
    }
    input:disabled, select:disabled{
        background-color: #f1f1f1 !important;
        cursor: not-allowed;
    }
}
.field-checkbox-wrap{
    display: block;
    background-color: var(--shade-0);
    transition: .1s ease;
    cursor: pointer;
    width: 100%;
    // padding: 4px 8px;
    color: var(--text-color);
    min-height: 40px;
    line-height: 20px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    &.active{
        .fake-checkbox{
            border-color: var(--quanternary) !important;
            background: var(--quanternary) !important;
            &::after{
                opacity: 1;
            }
        }
    }
    .fake-checkbox{
        flex-shrink: 0;
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 8px;
        border-radius: 2px;
        border: 1px solid var(--gray);
        position: relative;
        transition: 0.1s ease;
        &::after{
            opacity: 0;
            content: "";
            position: absolute;
            left: 3px;
            top: 0px;
            display: block;
            transition: 0.1s ease;
            width: 6px;
            height: 8px;
            border: solid white;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg) translateY(0.5px) translateX(1px);
        }
    }
}

// ng bootstrap datepicker

ngb-datepicker{
    box-shadow: 0 3px 12px rgb(144 144 144 / 25%) !important;
    border: none !important;
    &.dropdown-menu{
        min-width: 0 !important;
    }
}
.ngb-dp-navigation-select{
    .form-select{
        &:first-of-type{
                border: 0 !important;
                background: none !important;
                outline: none !important;
                text-align: right !important;
                font-weight: 500 !important;
                color: #000 !important;
                box-shadow: none !important;
                padding-right: 2px !important;
        }
        &:last-of-type{
                border: 0 !important;
                background: none !important;
                outline: none !important;
                text-align: left !important;
                font-weight: 500 !important;
                box-shadow: none !important;
                color: #000 !important;
                padding-left: 2px !important;
        }
    }
}
.ngb-dp-arrow{
    &:first-of-type{
        .ngb-dp-navigation-chevron{
            width: 7px !important;
            height: 7px !important;
            border-width: 1px 1px 0 0 !important;
            display: block !important;
        }
    }
    &:last-of-type{
        .ngb-dp-navigation-chevron{
            width: 7px !important;
            height: 7px !important;
            border-width: 1px 1px 0 0 !important;
            display: block !important;
        }
    }
}
.ngb-dp-header{
    background-color: transparent !important;
}
.ngb-dp-weekdays{
    background-color: transparent !important;
    border-bottom: none !important;
}
.bg-primary{
    background-color: var(--primary-color) !important;
}
.ngb-dp-weekday{
    color: #000 !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
}

// profile popover
.profile-popover{
    min-width: 260px;
    border-top: 2px solid var(--primary-color);
    &::after{
        content: '';
        border-style: solid;
        border-width: 0 3px 3px 3px;
        border-color: transparent transparent var(--primary-color) transparent;
        position: absolute;
        bottom: 100%;
        right: 13px;
    }
    .head{
        background: var(--popup-background-alt);
    }
    .options{
        ul{
            li{
                border: none;
                background: none !important;
                padding: 0;
                &.logout{
                    a{
                        color: var(--gray) !important;
                    }
                }
                &:last-of-type{
                    a div{
                        border-bottom: none;
                    }
                }
                a{
                    width: 100%;
                    transition: .1s ease;
                    font-size: 18px;
                    line-height: 24px;
                    color: var(--text-color);
                    padding-left: 18px;
                    padding-right: 18px;
                    text-decoration: none;
                    &:hover{
                        background: rgb(251, 251, 251);
                    }
                    div{
                        border-bottom: 1px solid #eaeaea;
                        padding-top: 12px;
                        padding-bottom: 12px;
                    }
                    * {
                        font-size: 18px;
                    }
                    span{
                        min-width: 27px;
                        i{
                            font-size: 18px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

// detail modal
.panel-head{
    border-bottom: 1px solid #506074;
    button{
        color: #fff !important;
        i{
            color: #fff !important;
        }
    }
    .titles{
        .title{
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 4px;
        }
        .subtitle{
            font-size: variables.$fontsize-s;
            line-height: 21px;
        }
    }
}
.detail-modal-header{
    padding: 32px 40px;
    background-color: #F9F9FA;
    background-color: #F4F6F9;
    h1{
        font-size: 32px;
        line-height: 48px;
        font-weight: 500;
    }
}
.detail-modal-body{
    padding: 40px;
}
.active-page-icon{
    color: var(--primary-color);
    font-size: 30px;
    margin-right: 14px;
    transform: translateY(-1px);
}

.content {
    .row-pagination{
        margin: 0 !important;
    }
    .emptystate-wrap{
        margin-top: 0 !important;
        .empty-state{
            font-weight: 300;
            font-size: 14px;
            padding-top: 20px;
        }
    }
}

// side panel
.side-panel-wrap{
    padding: 24px;
    width: variables.$sidePanelWidth;
    // background: var(--shade-2);
    // border-right: 1px solid var(--shade-3);
    height: calc(100vh - #{variables.$headerHeight});
    overflow-y: auto;
    position: fixed;
    top: variables.$headerHeight;
    z-index: 2;
}
.side-panel{
    border: 1px solid #edecec;
    // padding: variables.$spacing-m variables.$spacing-l variables.$spacing-s variables.$spacing-l;
    width: variables.$sidePanelWidth;
    // background: var(--shade-2);
    // border-right: 1px solid var(--shade-3);
    // height: calc(100vh - #{variables.$headerHeight});
    // overflow-y: auto;
    // position: fixed;
    // top: variables.$headerHeight;
    // z-index: 2;

    width: 100%;

    height: auto;
    padding: 16px 16px 32px 16px;
    border-radius: 8px;
    border: none;
    background: rgba(57, 70, 86, 0.05);
    .search-title{
        margin-bottom: 24px;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        color: var(--text-color);
    }
    .side-panel-footer{
        padding-top: variables.$spacing-s;
        font-size: variables.$fontsize-xxs;
        text-align: center;
        color: var(--shade-8);
        justify-self: end;
        width: 100%;
        .theme--dark & {
            color: var(--shade-3);
        }
        a{
            color: var(--secondary);
            text-decoration: underline;
            .theme--dark & {
                color: var(--white-fixed);
            }
        }
    }
    .field-date input{
        width: 100%;
    }
}
.side-panel-main{
    margin-left: variables.$sidePanelWidth;
    max-width: calc(100vw - #{variables.$sidePanelWidth});
    overflow: auto;
    z-index: 2;
}
.panel-footer{
    background: #ebebeb;
    .theme--dark & {
        background: #3a3a3a;
    }
    padding-top: 28px;
    padding-bottom: 28px;
}

// bootstrap overwrites
.dropdown-menu{
    min-width: 100%;
    border-radius: 2px;
    box-shadow: 0 2px 12px 0 rgba(144, 144, 144, 0.3) !important;
    margin: 0;
    padding: 0;
    border: none;
    max-height: 306px;
    overflow-y: auto;
    background: var(--popup-background);
}
.dropdown-item{
    // padding: 8px 0px;
    // margin-left: 10px;
    padding: 8px;
    color: var(--text-color);
    border-bottom: 1px solid var(--shade-3);
}
.dropdown-item.active, .dropdown-item:active{
    padding: 8px 10px;
    margin-left: 0px;
    background: var(--primary-color);
}
.dropdown-item.active .typeahead-after, .dropdown-item:active .typeahead-after{
    color: #efefef;
}

.ngb-highlight {
    background-color: rgba(176,74,151,0.16);
    font-weight: normal !important;
}

// selection detail modal
// .selection-detail-modal{
//     background: var(--background-color);
//     color: var(--text-color);
//     .panel-head{
//         border-color: #6c6e6d;
//     }
//     .edit, .cancel{
//         .theme--dark & {
//             color: var(--white-fixed);
//         }
//     }
//     .start-form{
//         transition: .1s ease;
//         &.edit-section{
//             background-color: #f7fbfe;
//             .theme--dark & {
//                 background-color: #282828;
//                 // background-color: var(--popup-background-alt);
//             }
//         }
//     }
// }


@media only screen and (max-width: 1600px) {
    .panel-head{
        padding-left: 32px !important;
        padding-right: 32px !important;
        button{
            margin-bottom: 32px !important;
        }
        .titles{
            .title{
                font-size: 22px;
                line-height: 30px;
                margin-bottom: 2px;
            }
        }
    }
}